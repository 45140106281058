export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
};

export const generalHoteType = {
  Hotel: "Hotels",
  HomeStay: "Home Stays",
};

