import logo from "../../assets/images/main_logo.png";
import favicon from "../../assets/images/favicon.png";
import avatar from "../../assets/images/avatar.png";
import brand from "../../assets/images/brand.jpg";
import product from "../../assets/images/product.jpeg";
import customer from "../../assets/images/customer.jpg";
export const images = {
  product,
  brand,
  logo,
  favicon,
  avatar,
  customer,
};
